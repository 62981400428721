import 'core-js-pure/es/set'
import 'core-js-pure/es/map'
import 'core-js-pure/es/symbol'
import 'core-js-pure/es/array'
import 'core-js-pure/es/object'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'

function addCustomEventPolyfill() {
  if (typeof window.CustomEvent === 'function') {
    // If not IE
    return false
  }

  function CustomEvent(
    event,
    params = { bubbles: false, cancelable: false, detail: undefined },
  ) {
    const { bubbles, cancelable, detail } = params
    const customEvent = document.createEvent('CustomEvent')

    customEvent.initCustomEvent(customEvent, bubbles, cancelable, detail)

    return customEvent
  }

  CustomEvent.prototype = window.Event.prototype

  window.CustomEvent = CustomEvent
}

addCustomEventPolyfill()
